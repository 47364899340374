/* Footer.css */

.footer {
    background-color: #111;
    color: #fff;
    padding: 40px 0;
    text-align: center;
    font-family: 'Arial', sans-serif;
    border-top: 3px solid #00aced;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .social-media {
    margin-bottom: 20px;
  }
  
  .social-media a {
    color: #fff;
    font-size: 24px;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-media a:hover {
    color: #00aced; /* Use a common hover color for consistency */
  }
  
  .developer-website {
    margin-bottom: 20px;
  }
  
  .developer-logo {
    width: 100px;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .developer-logo:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
  }
  
  .copyright {
    font-size: 14px;
    color: #aaa;
  }
  
  @media (max-width: 768px) {
    .social-media a {
      font-size: 20px;
      margin: 0 10px;
    }
  
    .developer-logo {
      width: 80px;
    }
  }
  