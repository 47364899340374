/* Overall dashboard container */
.admin-dashboard {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f4f6f8;
}

/* Sidebar */
.admin-sidebar {
  background-color: #2c3e50;
  color: white;
  width: 250px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.admin-sidebar ul {
  list-style: none;
  padding: 0;
}

.admin-sidebar li {
  padding: 15px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.admin-sidebar li:hover {
  background-color: #34495e;
}

/* Content area */
.admin-content {
  flex-grow: 1;
  padding: 40px;
  overflow-y: auto;
}

.admin-dashboard-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #2c3e50;
}

.add-new-item-link {
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  margin-bottom: 20px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.add-new-item-link:hover {
  background-color: #2980b9;
}

/* Search container */
.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Table styling */
.item-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.item-table th, .item-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.item-table th {
  background-color: #3498db;
  color: white;
}

.item-table td img {
  border-radius: 5px;
}

.edit-link {
  background-color: #f39c12;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-decoration: none;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.edit-link:hover {
  background-color: #e67e22;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #c0392b;
}

/* Responsive styles */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }

  .admin-sidebar {
    width: 100%;
    text-align: center;
  }

  .admin-content {
    padding: 20px;
  }
}
