/* Header Container */
.admin_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dcdcdc;
  padding: 10px 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Logo Container */
.admin_logo img {
  max-height: 50px;
  max-width: 150px;
}

/* Responsive header */
@media (max-width: 768px) {
  .admin_header {
    padding: 10px 15px;
  }

  .admin_logo img {
    max-height: 40px;
    max-width: 120px;
  }
}
