.filter {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  justify-content: center; /* Centers the items horizontally */
  align-items: center; /* Centers the items vertically within the container */
  overflow-x: auto; /* Adds horizontal scrolling for smaller screens */
  margin: 20px 0;
  padding-bottom: 10px; /* Adds some padding to avoid scrollbars touching content */
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; /* Adjust margin to control spacing between items */
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 90px; /* Keeps the width of the filter items fixed */
  flex-shrink: 0; /* Prevents items from shrinking */
}

.filter-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #ccc;
  padding: 5px;
  object-fit: scale-down;
  transition: all 0.3s ease;
}

.filter-item p {
  margin-top: 10px;
  font-size: 13px;
  color: #555;
}

.filter-item:hover img {
  border-color: #000;
  transform: scale(1.1);
}

.filter-item:hover p {
  color: #000;
}

/* Optional media queries for responsiveness */
@media (max-width: 768px) {
  .filter-item img {
    width: 45px;
    height: 45px;
  }

  .filter-item p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .filter-item {
    width: 70px;
  }

  .filter-item img {
    width: 40px;
    height: 40px;
  }

  .filter-item p {
    font-size: 11px;
  }
}
