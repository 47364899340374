/* Banner.css */

.banner {
  position: relative;
  height: 450px; /* Adjust the height to give more space for images */
  overflow: hidden;
  width: 95%;
  margin: 0 auto;
  max-width: 1400px; /* Slightly increased the max-width for larger screens */
  border-radius: 15px; /* More rounded corners */
  margin-top: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.banner .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the entire image is visible without cropping */
  border-radius: 15px;
  display: block;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay on top of the image */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s ease;
  border-radius: 15px;
}

.banner:hover .banner-overlay {
  opacity: 0.05; /* Show overlay on hover */
}

/* Hide dots (pagination) and arrows for all screen sizes */
.slick-dots,
.slick-prev,
.slick-next {
  display: none !important; /* Ensure they are hidden on all screens */
}

/* Responsive design */
@media (max-width: 768px) {
  .banner {
    height: 300px; /* Reduce height on smaller screens */
  }

  .banner-overlay {
    font-size: 18px;
  }
}
