/* General header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #dcdcdc;
  border-bottom: 2px solid #eaeaea;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

/* .header:hover {
  background-color: #f1f1f1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

/* Logo styling */
.logo img {
  height: 50px; /* Keep the logo slightly smaller for a compact design */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.logo img:hover {
  transform: scale(1.1);
}

/* Search bar container */
.search-bar {
  flex: 1;
  display: flex;
  justify-content: center;
}

.search-bar input {
  width: 70%;
  padding: 10px 18px;
  border-radius: 20px;
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  transition: all 0.3s ease;
  outline: none;
}

.search-bar input::placeholder {
  color: #999;
}

.search-bar input:focus {
  width: 75%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

/* Cart container */
.cart-container-header {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.cart-icon-header {
  height: 25px; /* Smaller cart icon */
  transition: transform 0.3s ease;
}

.cart-container-header:hover .cart-icon-header {
  transform: rotate(-10deg) scale(1.1);
}

/* Cart item count badge */
.cart-item-count-header {
  background-color: #ff4c60;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  padding: 6px 6px;
  position: absolute;
  top: -20px;
  right: -20px;
  min-width: 4px;
  max-height: 20px;
  text-align: center;
  line-height: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: bounce 1s infinite ease-in-out;
}

/* Bounce animation for cart count */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header {
    padding: 10px 20px;
  }

  .search-bar input {
    width: 85%;
  }

  .cart-icon-header {
    height: 20px; /* Smaller icon on mobile */
  }

  .logo img {
    height: 40px; /* Smaller logo on mobile */
  }
}
