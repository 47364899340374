.cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  margin: auto;
  margin-top: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-item-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.empty-cart {
  text-align: center;
  color: #888;
  padding: 20px 0;
}

.item-details {
  display: flex;
  align-items: center;
}

.item-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.item-info {
  display: flex;
  flex-direction: column;
}

.remove-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #c0392b;
}

.total-price {
  margin-top: 20px;
  font-weight: bold;
}

.order-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.order-button:hover {
  background-color: #2980b9;
}

/* Responsive styles */
@media (max-width: 768px) {
  .cart-container {
    padding: 15px;
  }
  
  .item-image {
    width: 40px;
    height: 40px;
  }

  .order-button, .remove-button {
    width: 100%;
    margin-top: 10px;
  }
}
