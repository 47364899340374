/* Smooth transitions for all animations */
* {
  transition: all 0.5s ease;
}
/* .app-main{
 background-color: #e7e7e7;
} */

/* Banner styles */
.banner-container {
  position: relative;
  height: 400px; /* Adjust based on your banner size */
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* When scrolling up, fade out the banner */
.banner-container.fade-out {
  opacity: 0;
  transform: scale(0.9); /* Slightly shrink the banner as it fades */
}

/* Sticky container for Filter and Item Grid */
.sticky-container {
  position: relative;
  top: 0;
  opacity: 1;
  transition: position 0.5s ease, opacity 0.5s ease;
}

/* When scrolling, make it sticky and fixed */
.sticky-container.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Adding some spacing to prevent overlap */
.item-grid {
  margin-top: 50px; /* Adjust to add spacing after the sticky filter */
}

/* Ensure body scrolls smoothly */
body {
  scroll-behavior: smooth;
  background-color: #ffffff;
}
.content{
  margin-top: 7%;

}
