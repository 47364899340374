/* ItemGrid.css */

/* Grid container for items */
.item-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 24px; /* Increased gap for better spacing */
  justify-content: center; /* Center the grid */
  padding: 20px;
  background-color: #ffffff; /* Light background for contrast */
}

/* Styling for individual items */
.item {
  background-color: #ffffff; /* White background for contrast */
  border: none; /* Removed border for a cleaner look */
  border-radius: 15px; /* More pronounced rounded corners */
  overflow: hidden; /* Ensures content stays within card */
  width: 280px; /* Increased width for more content space */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center align text */
  padding: 20px;
  height: 280px;
  background-image: linear-gradient(145deg, #ffffff, #e6e6e6); /* Subtle gradient */
}

/* Hover effect on items */
.item:hover {
  transform: translateY(-10px); /* Lift effect on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
}

/* Item image styling */
.item img {
  width: 100%;
  height: 180px; /* Set consistent height for images */
  object-fit: contain; /* Maintain aspect ratio, fill available space */
  border-radius: 10px; /* Rounded corners for images */
  margin-bottom: 15px;
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

.item img:hover {
  transform: scale(1.05); /* Slight zoom on image hover */
}

/* Item title styling */
.item h3 {
  font-size: 20px;
  margin: 2px 0;
  font-weight: 600;
  color: #333333;
}

/* Item description styling */
.item h5 {
  font-size: 16px;
  color: #777777;
  margin-bottom: 10px;
  height: 24px; /* Fixed height for uniformity */
  overflow: hidden; /* Truncate overflow text */
}

/* Item price styling */
.item p {
  font-size: 18px;
  color: #007bff; /* Blue color for price */
  margin-bottom: 12px;
  font-weight: bold;
}

/* Order Button Styling */
.item button {
  background-color: #28a745; /* Green background for action button */
  color: white;
  border: none;
  padding: 10px 25px;
  border-radius: 25px; /* Rounded pill shape */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.item button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: translateY(-2px); /* Lift effect on hover */
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
  background: #ffffff; /* Solid white background */
  padding: 40px 30px;
  border-radius: 20px; /* More pronounced rounded corners */
  max-width: 700px;
  width: 90%;
  z-index: 1000;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2); /* Stronger shadow for depth */
  position: relative;
  opacity: 0;
  animation: modalShow 0.4s forwards; /* Enhanced fade-in and scale animation */
  text-align: left; /* Left align content for better readability */
  background-image: linear-gradient(145deg, #ffffff, #f0f0f0); /* Subtle gradient background */
}

/* Overlay for Modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Darker overlay */
  z-index: 500;
  opacity: 0;
  animation: overlayShow 0.4s forwards; /* Fade-in effect for overlay */
}

/* Modal content transitions */
@keyframes modalShow {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Close button enhancements */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #ff4d4d; /* Red button for closing */
  border: none;
  font-size: 20px;
  color: white;
  cursor: pointer;
  width: 35px;
  height: 35px;
  border-radius: 50%; /* Circular button */
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}
.close-button svg {
  width: 20px;
  height: 20px;
}


.close-button:hover {
  background-color: #e60000; /* Darker red on hover */
  transform: rotate(90deg); /* Rotate effect on hover */
}

/* Modal Image Styling */
.modal img {
  width: 100%;
  height: 250px; /* Increased height for better visibility */
  object-fit: cover; /* Maintain aspect ratio, fill available space */
  border-radius: 15px; /* Smoothly rounded corners */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow for depth */
  margin-bottom: 25px; /* Increased space between image and text */
  transition: transform 0.3s ease; /* Smooth zoom effect */
}

.modal img:hover {
  transform: scale(1.02); /* Slight zoom on image hover */
}

/* Modal Title Styling */
.modal h2 {
  font-size: 32px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 15px;
}

/* Modal Description */
.modal p {
  font-size: 18px;
  color: #555555;
  margin-bottom: 20px;
  line-height: 1.6; /* Improved readability */
}

/* Price Styling */
.modal strong {
  font-size: 24px;
  color: #000000;
  display: block;
  margin-bottom: 25px;
}

/* Order Button Styling */
.modal button {
  background-color: #17a2b8; /* Teal background for action button */
  color: white;
  border: none;
  padding: 14px 30px;
  border-radius: 30px; /* Rounded pill shape */
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow */
}

.modal button:hover {
  background-color: #138496; /* Darker teal on hover */
  transform: translateY(-3px); /* Lift effect on hover */
}


/* Responsive Design */
@media (max-width: 768px) {
  .modal {
    padding: 30px 20px;
  }

  .modal h2 {
    font-size: 26px;
  }

  .modal p, .modal strong {
    font-size: 16px;
  }

  .modal button {
    padding: 12px 25px;
    font-size: 16px;
  }

  .item {
    width: 220px;
    padding: 15px;
  }

  .item img {
    height: 150px;
  }

  .item h3 {
    font-size: 18px;
  }

  .item p {
    font-size: 16px;
  }
}
